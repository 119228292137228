import React from 'react';
import {
  NavLink,
  withRouter
} from 'react-router-dom';

import commupLogo from '../commup-logo.svg';

import { Auth } from '@commup/api-connect';

const AppHeader = (props) => {
  const profileLink = props.signedIn ?
    <span onClick={ Auth.logout }>Logout</span> :
    <NavLink to="/sign-in">Login</NavLink>;

  return (
    <div className="app-header">
      <div
        className="commup"
        onClick={ () => props.history.push('/') }
      >
        <img
          src={ commupLogo }
          alt="logo"
        />
        <h1 className="title">
          { 'CommUp' }
        </h1>
      </div>
      <ul className="links">
        <li>
          <NavLink to="/games">{ 'Games' }</NavLink>
        </li>
        <li>{ profileLink }</li>
      </ul>
    </div>
  )
}

export default withRouter(AppHeader);
