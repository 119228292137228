import React from 'react';

import {
  NavLink,
  withRouter
} from 'react-router-dom';

import { Auth } from '@commup/api-connect';

import Button from '../components/Button';
import Input from '../components/Input';

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLoading: false
    };

    this.signUp = this.signUp.bind(this);
  }

  async signUp() {
    this.setState({ isLoading: true });
    try {
      await Auth.signUp(this.state.email, this.state.password);
      this.props.history.push("/");
    } catch (error) {
      //TODO: error handling
      window.alert(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="page-sign-up">
        <h1 className="header">{ 'Sign Up' }</h1>
        <Input
          label="Email"
          type="email"
          onChange={ (email) => this.setState({ email }) }
        />
        <Input
          label="Password"
          type="password"
          onChange={ (password) => this.setState({ password }) }
        />
        <Button
          isDisabled={ !this.state.email || !this.state.password || this.state.isLoading }
          isLoading={ this.state.isLoading }
          onClick={ this.signUp }
          text="Sign Up"
        />
      <NavLink to="/sign-in">{ 'Already have an account? Click here to sign in!' }</NavLink>
      </div>
    );
  }
}

export default withRouter(SignUp);
