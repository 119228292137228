import React from 'react';

import commupLogo from '../commup-logo.svg';

import Button from '../components/Button';

const Home = (props) => (
  <div className="page-home">
    <h1 className="title">{ 'Gaming Together' }</h1>
    <h2 className="sub-title">{ 'Find new friends to party up with in your favorite games!' }</h2>
    <img
      src={ commupLogo }
      alt="logo"
    />
    <Button
      url={ props.signedIn ? '/games' : '/sign-up' }
      text={ props.signedIn ? 'Comm Up!' : 'Sign Up Today' }
    />
  </div>
);

export default Home;
