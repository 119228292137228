import React from 'react';

import { withRouter } from 'react-router-dom';

import { Game as GameApi } from '@commup/api-connect';

import GameCard from '../components/GameCard';
import Loader from '../components/Loader';

class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      game: null,
      isLoading: true
    };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;

    if (!id) {
      return;
    }

    try {
      const game = await GameApi.getGame(id);
      this.setState({ game });
    } catch (error) {
      //TODO: error handling pls
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="page-game-list">
          <Loader />
        </div>
      );
    }

    return (
      <div className="page-game-list">
        <h1 className="title">{ this.state.game.name }</h1>
        <GameCard game={ this.state.game } />
      </div>
    );
  }
}

export default withRouter(Game);
