import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { initializeApp } from '@commup/api-connect';

import './styles/index.scss';
import App from './App';


const firebaseConfig = {
  apiKey: "AIzaSyDeDIqDClf4dBHlzCXpncuOpj7-K3cZPns",
  authDomain: "commup-43446.firebaseapp.com",
  databaseURL: "https://commup-43446.firebaseio.com",
  projectId: "commup-43446",
  storageBucket: "commup-43446.appspot.com",
  messagingSenderId: "493714331257",
  appId: "1:493714331257:web:b1877f8408a39fdd8ce6a8"
};

initializeApp({ firebaseConfig });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
