import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selected: false };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const inputClasses = classNames(
      'component-input',
      this.props.className,
      { 'selected': this.state.selected }
    );

    const error = this.props.error && (
      <span className="error">{ this.props.error }</span>
    );

    return (
      <div
        id={ this.props.id }
        className={ inputClasses }
        onClick={ () => { this.myInp.focus() } }
      >
        <span className="label">{ this.props.label }</span>
        <input
          ref={(ip) => this.myInp = ip}
          type={ this.props.type }
          value={ this.props.value }
          onChange={ this.handleChange }
          onBlur={ () => this.setState({ selected: false }) }
          onFocus={ () => this.setState({ selected: true }) }
          placeholder={ this.props.placeholder }
        />
        { error }
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,

  onChange: PropTypes.func,

  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
};

export default Input;
