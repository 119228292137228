import React from 'react';

import {
  HashRouter,
  Route
} from 'react-router-dom';

import { Auth, User } from '@commup/api-connect';

import AppHeader from './components/AppHeader';
import UsernameAppHeader from './components/UsernameAppHeader';

import Game from './pages/Game';
import GameList from './pages/GameList';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Username from './pages/Username';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appInitialized: false,
      hasUsername: false,
      signedIn: false,
    };

    this.getUserDetails = this.getUserDetails.bind(this);
  }

  async getUserDetails() {
    const user = await User.getUser();

    this.setState({ appInitialized: true });
    if (user.username) {
      this.setState({ hasUsername: true });
    }
  }

  componentDidMount() {
    Auth.onAuthStateChanged = (signedIn) => {
      this.setState({ appInitialized: false, signedIn });
      if (signedIn) {
        this.getUserDetails();
      } else {
        this.setState({ appInitialized: true })
      }
    };
  }

  render() {
    if (!this.state.appInitialized) {
      return (
        <div className="app" />
      );
    }

    if (this.state.signedIn && !this.state.hasUsername) {
      return (
        <div className="app">
          <UsernameAppHeader />
          <div className="content">
            <Username />
          </div>
        </div>
      );
    }

    return (
      <HashRouter>
        <div className="app">
          <AppHeader signedIn={ this.state.signedIn } />
          <div className="content">
            <Route exact path="/">
              <Home signedIn={ this.state.signedIn } />
            </Route>
            <Route exact path="/sign-in">
              <SignIn />
            </Route>
            <Route exact path="/sign-up">
              <SignUp />
            </Route>
            <Route exact path="/games/:id">
              <Game />
            </Route>
            <Route exact path="/games">
              <GameList />
            </Route>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default App;
