import React from 'react';

import commupLogo from '../commup-logo.svg';

const UsernameAppHeader = () => (
  <div className="username-app-header">
    <div className="commup">
      <img
        src={ commupLogo }
        alt="logo"
      />
      <h1 className="title">{ 'CommUp' }</h1>
    </div>
  </div>
);

export default UsernameAppHeader;
