import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

/*
 * A clickable piece of text
 */
const Button = (props) => {
  const buttonClasses = classNames(
    'inner-button',
    props.color,
    `size-${props.size}`,
    {
      [`background-${props.backgroundColor}`]: props.backgroundColor,
      'disabled': props.isDisabled,
      'loading': props.isLoading
    }
  );

  const wrapperClasses = classNames(
    'component-button',
    props.className,
    { [`background-${props.backgroundColor}`]: props.backgroundColor }
  );

  const isLink = props.url && !props.isDisabled;

  const loader = (
    <span>LOADING...</span>
  );

  const button = (
    <button
      id={ isLink ? null : props.id }
      disabled={ props.isDisabled || props.isLoading }
      className={ isLink ? buttonClasses : classNames(wrapperClasses, buttonClasses) }
      onClick={ !props.isDisabled && props.onClick && !props.isLoading ? props.onClick : undefined }
    >
      { props.isLoading ? loader : props.text }
    </button>
  );

  return isLink ? (
    <NavLink
      id={ props.id }
      className={ wrapperClasses }
      to={ props.url }
    >
      { button }
    </NavLink>
  ) : button;
};

Button.SIZES = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large'
};

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,

  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,

  size: PropTypes.oneOf(_.values(Button.SIZES)),
  text: PropTypes.node,

  /*
   * A function to run on click
   */
  onClick: PropTypes.func,
  url: PropTypes.string
};

Button.defaultProps = {
  size: Button.SIZES.NORMAL
};

export default Button;
