import React from 'react';

import {
  NavLink,
  withRouter
} from 'react-router-dom';

import { Auth } from '@commup/api-connect';

import Button from '../components/Button';
import Input from '../components/Input';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLoading: false
    };

    this.signIn = this.signIn.bind(this);
  }

  async signIn() {
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.history.push("/");
    } catch (error) {
      //TODO: error handling
      window.alert(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="page-sign-in">
        <h1 className="header">{ 'Sign In' }</h1>
        <Input
          label="Email"
          type="email"
          onChange={ (email) => this.setState({ email }) }
        />
        <Input
          label="Password"
          type="password"
          onChange={ (password) => this.setState({ password }) }
        />
        <Button
          isDisabled={ !this.state.email || !this.state.password || this.state.isLoading }
          isLoading={ this.state.isLoading }
          onClick={ this.signIn }
          text="Sign In"
        />
        <NavLink to="/sign-up">Need an account? Click here to sign up!</NavLink>
      </div>
    );
  }
}

export default withRouter(SignIn);
