import _ from 'lodash';
import React from 'react';

import { Game } from '@commup/api-connect';

import GameCard from '../components/GameCard';
import Loader from '../components/Loader';

class GameList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      games: [],
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      const games = await Game.getGames();
      this.setState({ games });
    } catch (error) {
      //TODO: error handling pls
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="page-game-list">
          <Loader />
        </div>
      );
    }

    const gameCards = _.map(this.state.games, (game) => (
      <GameCard
        key={ game.id }
        hasLink
        game={ game }
      />
    ));

    return (
      <div className="page-game-list">
        <h1 className="title">{ 'Games' }</h1>
        <div className="game-grid">
          { gameCards }
        </div>
      </div>
    );
  }
}

export default GameList;
