import React from 'react';
import classNames from 'classnames';

import { withRouter } from 'react-router-dom';

const GameCard = (props) => {
  const cardClasses = classNames('game-card', { clickable: props.hasLink });
  const onClick = props.hasLink ?
    () => props.history.push(`/games/${props.game.id}`) :
    undefined;

  return (
    <div
      className={ cardClasses }
    >
      <img
        src={ props.game.cover }
        onClick={ onClick }
        alt={ props.game.name }
      />
    </div>
  )
};

export default withRouter(GameCard);
