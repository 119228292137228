import React from 'react';

import {
  Auth,
  User
} from '@commup/api-connect';

import Button from '../components/Button';
import Input from '../components/Input';

class Username extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      isLoading: false
    };

    this.submitUsername = this.submitUsername.bind(this);
  }

  async submitUsername() {
    this.setState({ isLoading: true });
    try {
      await User.setUsername(this.state.username);
      Auth.onAuthStateChanged(true);
    } catch (error) {
      //TODO: error handling
      window.alert(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="page-username">
        <h1 className="header">{ 'Choose a Username' }</h1>
        <h2 className="description">{ 'You must have a username to use CommUp' }</h2>
        <Input
          label="Username"
          onChange={ (username) => this.setState({ username }) }
        />
        <Button
          isDisabled={ !this.state.username || this.state.isLoading }
          isLoading={ this.state.isLoading }
          onClick={ this.submitUsername }
          text="Save Username"
        />
      </div>
    );
  }
}

export default Username;
